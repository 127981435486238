import React from "react";
import { Container,Row, Col } from "react-bootstrap";
import HtmlParser from "react-html-parser";
import { Link } from "gatsby";
import { getSpacingClass } from "../../common-data/common-data";
import ImageRenderer from "../../ImageRenderer/ImageRenderer";
import "./FullWidthTwoColModule.scss";

const FullWidthTwoColModule = ({ ModuleData,  ...PageData }) => {
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  const image_url = ModuleData?.Full_Width_Two_Col_Image?.internal.description
  ? ModuleData.Full_Width_Two_Col_Image.internal.description.replace(/File |"/g, '')
  : "";
  return (
    <div className={`fullwidth-two-col-module ${spacingClass}`}>
     <Container fluid>
        <Row>
          <Col xs={12} lg={6} className="left-column">
            <ImageRenderer ImageSrc={{url:image_url}} altText="Anthony Pepe" ggfx_results={PageData?.ggfx_results} strapi_id={PageData?.StrapiId} imagename="articles.images.TitleImage" className="left-image" />            
          </Col>
          <Col xs={12} lg={6} className="right-column">
            <div className="content">
              {ModuleData?.Full_Width_Two_Col_Title && 
                 <h3 className="spacing-bottom-32">{ModuleData?.Full_Width_Two_Col_Title}</h3>
              }
              {HtmlParser(ModuleData?.Full_Width_Two_Col_Content)}
              {ModuleData?.Full_Width_Two_Col_CTA_URL && 
                <div className="spacing-top-48">
                    <Link to={ModuleData?.Full_Width_Two_Col_CTA_URL} className="btn"> {ModuleData?.Full_Width_Two_Col_CTA_Label}</Link>
                </div>
              }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FullWidthTwoColModule;