import React from 'react';
import { Link } from 'gatsby';
import ImageRenderer from "../ImageRenderer/ImageRenderer";

import { getPropertyUrl } from "../common-data/common-data";
import './PropertyCard.scss';

const PropertyCard = (props) => {
  const propertyUrl = getPropertyUrl(props?.searchType, props?.slug, props?.id);

  return (
    <div className="property-card">
        <div key={props?.id}>
            <div className="img-wrap spacing-bottom-24">
            <Link to={propertyUrl}><ImageRenderer ImageSrc={props?.image} altText={props?.title} ggfx_results={props?.ggfx_results} strapi_id={props?.id} imagename="property.images.similar"  /></Link>
            </div>
            <div className="content-wrap">
                <h4 className="prop-card-title text-22 bold"><Link className="text-22 bold" to={propertyUrl}>{props?.title}</Link></h4>
                <p className="prop-card-price text-20 bold">{'£' + props?.price.toLocaleString()}</p>
                <p className="text-18">{props?.bedroom}</p>                
            </div>
        </div>
    </div>
  );
};
export default PropertyCard;