import React, { useState } from "react";
import { Container, Accordion } from "react-bootstrap";
import HtmlParser from 'react-html-parser';
import { Link } from "gatsby";
import { getSpacingClass } from "../../common-data/common-data";
import { PlusDark, Minus } from "../../icon/icon";

import "./FAQModule.scss";
const FAQModule = ({ ModuleData, ...PageData }) => {

  const [expandedIndex, setExpandedIndex] = useState(null);
  
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);

  const toggleAnswer = (index) => {
    setExpandedIndex(prevIndex => (prevIndex === index ? null : index));
  };
  
  return (
    <div className={`faq-module ${spacingClass}`}>
      <Container>
        <div className="faq-content">
        <div className="heading-section spacing-bottom-48">
            <h3>{ModuleData?.Heading || "Frequently Asked Questions"}</h3>
        </div>           
        <div className="cta-link-wrapper">
              <Link to="" className="btn btn-transparent-black">{ModuleData?.CTA_Label}</Link>
        </div>
        <div className="faq-accordion">
          <Accordion className="m-0">
            {ModuleData?.FAQ_Items.map((faq, index) => (
              <div className="accordion-item" key={faq.id}>
                <div 
                  className="faq-question text-20 bold" 
                  onClick={() => toggleAnswer(index)}
                >
                  <span>{faq.Question}</span>
                  <span className="faq-icon">
                    {expandedIndex === index ? <Minus /> : <PlusDark />}
                  </span>
                </div>
                {expandedIndex === index && (
                  <div className="faq-answer">
                    {HtmlParser(faq.Answer)}
                  </div>
                )}
              </div>
            ))}
          </Accordion>
        </div>
        </div>
      </Container>     
    </div>
  );
};

export default FAQModule;